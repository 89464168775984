// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-free-home-evaluation-js": () => import("./../../../src/pages/free-home-evaluation.js" /* webpackChunkName: "component---src-pages-free-home-evaluation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-services-access-control-js": () => import("./../../../src/pages/services/access-control.js" /* webpackChunkName: "component---src-pages-services-access-control-js" */),
  "component---src-pages-services-energy-management-js": () => import("./../../../src/pages/services/energy-management.js" /* webpackChunkName: "component---src-pages-services-energy-management-js" */),
  "component---src-pages-services-health-wellness-monitoring-js": () => import("./../../../src/pages/services/health-wellness-monitoring.js" /* webpackChunkName: "component---src-pages-services-health-wellness-monitoring-js" */),
  "component---src-pages-services-home-automation-js": () => import("./../../../src/pages/services/home-automation.js" /* webpackChunkName: "component---src-pages-services-home-automation-js" */),
  "component---src-pages-services-home-monitoring-js": () => import("./../../../src/pages/services/home-monitoring.js" /* webpackChunkName: "component---src-pages-services-home-monitoring-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

